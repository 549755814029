<template>
  <div class="searchList">
    <i-header></i-header>
    <div class="dbkbox">
      <div class="boxcont" ref="boxcont">
        <div class="labelPage">
          <i-navigate :navigate="channel" :barData="channelChild"></i-navigate>
        </div>
        <!--左侧栏目-->
        <div class="agivraph" v-if="leftmenu.length>0">
          <ul>
            <li v-for="(item,index) in leftmenu" :key="index" class="ellipsis" :class="agive == index?'afiactive':''" @click="changeChannel(item,index)">{{item.name}}</li>
          </ul>
        </div>
        <div class="width1100">
          <div class="record">
            <div>
                <span>共{{ totalCount }}条记录</span>
            </div>
            <div class="overall">
              <p @click="overone" class="tadit" :class="{ cross: cross }"></p>
              <p @click="overtwo" class="liken" :class="{ fore: fore }"></p>
            </div>
          </div>

          <div class="result wow fadeIn" data-wow-delay=".1s" v-show="result">
              <div class="strip" v-if="nowItem" @click="detail(nowItem)">
                <div class="resultImg">
                  <img :src="nowItem.titleImage" v-if="nowItem.titleImage">
                  <div class="default" v-else></div>
                </div>
                <div class="info">
                  <a v-html="nowItem.name"></a>
                  <p class="p2 ellipsis3" v-html="nowItem.description"></p>
                </div>
              </div>
            <ul>
            <waterfall :data="resuleData" :col="1" >
              <template v-for="(item, index) in resuleData">
                <li  :key="index" @click="detail(item)">
                  <div class="resultImg">
                    <img :src="item.titleImage" v-if="item.titleImage" />
                    <div class="default" v-else></div>
                  </div>
                  <div class="info">
                    <a v-html="item.name"></a>
                    <p class="p2 ellipsis3" v-html="item.description"></p>
                  </div>
                </li>
              </template>
            </waterfall>
            </ul>
          </div>

          <div class="arranage wow fadeIn"  v-if="arranage">
            <waterfall :data="resuleData" :col="4" >
              <div @click="detail(nowItem)" v-if="nowItem"  style="position:relative;box-shadow: 1px 2px 20px 0px #ffffff;">
                <div class="arrImg">
                  <img :src="nowItem.titleImage" v-if="nowItem.titleImage" />
                  <div class="default" v-else></div>
                </div>
                <p class="wahead">
                  <span></span><b v-html="nowItem.name"></b>
                </p>
                <p class="ellipsis3 watext">{{ nowItem.description }}</p>
              </div>
              <template v-for="(items, indexs) in resuleData">
                <div :key="indexs" @click="detail(items)" style="position:relative;">
                  <!--收藏-->
                  <!-- <div class="favor" :class="items.isCollect == 1 ? 'favortive' : ''"  @click.stop="collect(items, indexs)"></div> -->
                  <div class="arrImg">
                    <img :src="items.titleImage" v-if="items.titleImage" />
                    <div class="default" v-else></div>
                  </div>
                  <p class="wahead">
                    <span></span><b v-html="items.name"></b>
                  </p>
                  <p class="ellipsis3 watext">{{ items.description }}</p>
                </div>
              </template>
            </waterfall>
          </div>
          <div style="color: #ffffff">{{ loading ? "没有更多了" : "加载中" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import {getItemList} from "@/api/home";
import iHeader from "@/components/Header.vue";
import iNavigate from "@/components/navigate.vue";
import { collectItem } from "@/api/userCenter";
export default {
  name: "WaterfallFlow",
  components: {
    iHeader,
    iNavigate
  },
  data() {
    return {
      resuleData: [],
      pageNo: 1,
      uuid: "",
      channel:{},
      channelChild:[],
      loading: false,//加载提示
      canload:true,//防止一次下拉，多次加载
      favortive:false,
      agive:undefined,
      leftmenu:[],
      totalCount:"",
      result: true,
      arranage: false,
      cross: false,
      fore: false,
      nowItem:"",
    };
  },
  props:{
    channelData:Object,
  },
  mounted() {
    new WOW().init();
    this.$nextTick(()=>{
      window.addEventListener("scroll", this.windowScroll);
    })
  },
  created() {
    this.getResult();
  },
  destroyed(){
    window.removeEventListener("scroll", this.windowScroll);//销毁滚动事件
  },
  methods: {
    windowScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let scrollHeight=document.documentElement.scrollHeight || document.body.scrollHeight;
      let clientHeight=document.documentElement.clientHeight || document.body.clientHeight;
       
      if(scrollTop+clientHeight+10>=scrollHeight){
        this.loadMore();
      }
    },
    /*纵向排列*/
    overone() {
      this.fore = false;
      this.cross = false;
      this.result = true;
      this.arranage = false;
    },
    /*瀑布流排列*/
    overtwo() {
      this.cross = true;
      this.fore = true;
      this.result = false;
      this.arranage = true;
    },
    /*收藏*/
    collect(items, index) {
      if (items.isCollect == 1) {
        collectItem(items.uuid, {
          type: 0,
          /*ids: items.uuid*/
        }).then((res) => {
          this.$set(this.resuleData, index, {
            ...this.resuleData[index],
            isCollect: 0,
          });
        });
      } else {
        collectItem(items.uuid, {
          type: 1,
         /* ids: items.uuid*/
        }).then((res) => {
          this.$set(this.resuleData, index, {
            ...this.resuleData[index],
            isCollect: 1,
          });
        });
      }
    },
    /*logo跳转首页*/
    home() {
      this.$router.push({
        name: "index",
      });
    },
    /*详情*/
    /*detail(item) {
      this.$router.push({
        name: "item",
        query: { sortId: item.uuid },
      });
    },*/
    detail(item) {
      const{ href } = this.$router.resolve({
        name: "item",
        query: { sortId: item.uuid },
      });
      window.open(href,"_blank")
    },
    /*列表接口*/
    getResult() {
      this.reset();
      let res = this.channelData;
      this.leftmenu=res.child;
      this.uuid=res.channel.uuid;
      this.agive = undefined;
      this.channel=res.channel;
      this.channelChild=res.child;
      this.nowItem = res.channel.nowItem;
      this.totalCount = res.items.totalCount;
      let arr=res.items.list.map((item)=>{return {
        uuid:item.uuid,
        titleImage:item.titleImage?(item.titleImage.indexOf("?")>=0?(item.titleImage+'&h=300'):(item.titleImage+'?h=300')):'',
        name:item.name,
        description:item.description
      }});
      this.resuleData = arr;
      this.changeLoadingState(res.items);
    },
    /*下拉加载*/
    loadMore() {
      if (!this.canload) return;
      this.pageNo += 1;
      this.canload=false;
      getItemList(this.uuid,{pageNo: this.pageNo,"item": 1}).then((res) => {
        this.totalCount = res.items.totalCount;
        let arr=res.items.list.map((item)=>{return {
              uuid:item.uuid,
              titleImage:item.titleImage?(item.titleImage.indexOf("?")>=0?(item.titleImage+'&h=300'):(item.titleImage+'?h=300')):'',
              name:item.name,
              description:item.description
        }});
        this.canload=true;
        this.resuleData.push(...arr);
        this.changeLoadingState(res.items);
      });
          
      
    },
    /**
     * 延迟1秒修改加载状态
     */
    changeLoadingState(items){
      setTimeout(()=>{
        if(!items.list.length || this.pageNo==items.totalPage) {
          this.loading = true;
          this.canload=false;
        }
      },1000);
    },
    reset(){
      this.pageNo=1;
      this.resuleData=[];
      this.loading = false;
    },
    changeChannel(item,index){
      this.canload=false;
      this.reset();
      this.uuid=item.uuid;
      getItemList(item.uuid,{pageNo: this.pageNo,"item": 1}).then((res) => {
        this.totalCount = res.items.totalCount;
        this.nowItem = res.channel.nowItem;
        let arr=res.items.list.map((item)=>{return {
          uuid:item.uuid,
          titleImage:item.titleImage?(item.titleImage.indexOf("?")>=0?(item.titleImage+'&h=300'):(item.titleImage+'?h=300')):'',
          name:item.name,
          description:item.description
        }});
        this.resuleData = arr;
        this.agive = index;
        this.canload=true;
        this.changeLoadingState(res.items);
      });
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
/*收藏*/
.favor{
  position: absolute;
  top: 6px;
  right: 6px;
  width: 32px;
  height: 32px;
  background: rgba(0,0,0,.48) url("../../assets/images/shoucang.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  border-radius: 4px;
  z-index: 1;

}
.favortive{
  background: rgba(0,0,0,.48) url("../../assets/images/shoucang1.png") no-repeat;
  background-size: 20px 20px;
  background-position: center center;
}
.dbkbox {
  min-height: 100vh;
  height: auto;
  background-image: url("../../assets/images/pblbg.png");
  background-size: 100% 100%;
  background-attachment: fixed;
}
.boxcont {
  min-height: 100vh;
}
.width1100 {
  width: 1100px;
  margin: auto;
  position: relative;
  padding-top: 120px;
}
.antistop {
}
.default {
  position: absolute;
  background-image: url("../../assets/images/fenlei.png");
  background-size: 100% 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.strip{
  background: #ffffff;
  padding: 10px;
  display: flex;
  cursor: pointer;
  margin-bottom: 50px;
  border-radius: 4px;
  box-shadow: 1px 2px 20px 0px #ffffff;
}
.result {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.result ul li {
  background: #ffffff;
  padding: 10px;
  display: flex;
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
}
.result ul li:hover{
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.21);
  transition: 0.3s;
}
.result .info,.strip .info {
  padding-left: 22px;
  text-align: left;
}
.result a,.strip a {
  display: inline-block;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  padding: 7px 0px 15px 0px;
  /* text-decoration: underline;*/
}
.result .p2 ,.strip .p2{
  color: #333333;
  font-size: 16px;
  line-height: 30px;
  padding-right: 30px;
}
.result .resultImg ,.strip .resultImg{
  width: 180px;
  height: 135px;
  flex-shrink: 0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.result .resultImg img,.strip .resultImg img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.ellipsis3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.arranage{
  padding-top:20px;
}
.arranage ul {
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
}
.arranage ul li {
  width: 260px;
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 18px;
}
.arranage .arrImg {
  min-height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  top: -1px;
}
.arranage ul li:nth-child(4n) {
  margin-right: 0;
}
.arranage .wahead {
  padding-top: 15px;
  display: block;
  font-size: 18px;
  color: #333333;
  text-align: left;
  cursor: pointer;
}
.arranage p span {
  width: 4px;
  height: 16px;
  background: #ff5252;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}
.arranage p b {
  color: #333333;
  padding-left: 13px;
}
.arranage p {
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: left;
}
.arranage img {
  width: 100%;
}
.arranage >>> .vue-waterfall-column > div {
  background: #ffffff;
  margin-bottom: 20px;
  padding-bottom: 25px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
}
.arranage >>> .vue-waterfall-column > div:hover {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.28);
  transition: 0.3s;
}
.watext {
  padding: 15px 18px 0px 18px;
  box-sizing: border-box;
  max-height: 90px;
}

/*菜单*/
.record {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 0px 15px 0px;
  border-bottom: 1px solid #606266;
  margin-right: 20px;
}
.record span {
  color: #999999;
  font-size: 14px;
}
.overall {
  display: flex;
  cursor: pointer;
}
.overall p {
  width: 20px;
  height: 18px;
}
.overall .tadit {
  margin-right: 18px;
  background-image: url("../../assets/images/dbk/heng.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.overall .liken {
  background-image: url("../../assets/images/dbk/zong.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.overall .cross {
  background-image: url("../../assets/images/dbk/heng1.png");
  background-size: 100% 100%;
}
.overall .fore {
  background-image: url("../../assets/images/dbk/zong2.png");
  background-size: 100% 100%;
}
.carte ul {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}
.carte ul li {
  color: #333333;
  font-size: 14px;
  margin-right: 30px;

  cursor: pointer;
}
.carte ul p {
  font-size: 20px;
  font-weight: 800;
  padding-right: 35px;
}
.carte ul .active {
  color: #ff5252;
}
/*目录条*/
.labelPage {
  font-size: 14px;
  font-weight: 500;
  color: #b3b3b3;
  text-align: left;
  padding-left: 25px;
  padding-top: 114px;
  position: fixed;
}
/*左侧栏目*/
.agivraph{
  position: fixed;
  top: 230px;
}
.agivraph li{
  width: 172px;
  height: 48px;
  background: rgba(255,255,255,.12);
  border-radius: 1px 24px 24px 1px;
  cursor: pointer;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 48px;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 15px;
  box-sizing: border-box;
}
.agivraph .afiactive{
  background: #FF5076;
}
</style>
<style>

.boxcont .el-icon-arrow-right:before {
  content: url("../../assets/images/dbk/next.png");
}
.boxcont .el-icon-arrow-left:before {
  content: url("../../assets/images/dbk/last.png");
}
html,
body,
#app {
  background: #eff5f2 !important;
}
.vue-waterfall-easy {
  width: 1100px !important;
  height: 100%;
}
.vue-waterfall-easy-container > .loading {
  display: none !important;
}
.searchList .box_logo,.searchList #two{
  display: block;
}
</style>
