<template>
  <div style="height: 100%">
    <dair v-if="templateName=='dair'" :channelData="channelData"></dair>
    <waterfall-flow v-else-if="templateName=='waterfallFlow'" :channelData="channelData"></waterfall-flow>
    <classify v-else-if="templateName=='classify'" :channelData="channelData"></classify>
    <sector v-else-if="templateName=='sector'" :channelData="channelData"></sector>
  </div>
</template>

<script>
import {getItemList} from '@/api/home'
import Dair from './3d.vue';
import WaterfallFlow from './waterfallFlow.vue';
import Classify from './tui_la_men.vue';
import Sector from './sector.vue';
export default {
  name: "list",
  components: {
    Dair,//3d栏目页
    WaterfallFlow,//瀑布流栏目页
    Classify,//推拉门栏目页
    Sector//扇形栏目页
  },
  data() {
    return {
      templateName: "",
      channelData: "",
    };
  },
  watch: {
    "$route.query": {
      handler: function (val) {
        if (val && val.sortId) {
          getItemList(val.sortId).then((res) => {
            this.channelData=res;
            this.templateName=res.channel.templateName;
          });
        }else{
          this.$router.push({name: "index"});
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
