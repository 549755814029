<template>
  <div class="index">
    <i-header></i-header>
    <div class="labelPage">
      <i-navigate :navigate="channel"></i-navigate>
    </div>
    <ul class="ulFlex" ref="ulFlex">
      <li
        v-for="(img, index) in imgDate"
        :key="index"
        @mouseenter="showSpan(img,index)"
        @mouseleave="showLeave()"
        @click="!img.nowItem && openChannel(img)"
      >
        <a>
          <div style="height: 100%; position:relative;" class="pict">
            <img :src="img.img"/>
          </div>
          <div class="bgOpcity"></div>
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut">
            <span  class="swiperName">{{ img.name }}</span>
          </transition>
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <div class="entry" v-show="index ==entryShow">
              <div @click="openChannel(img)" class="press">
                <label style="border:1px solid rgba(255, 255, 255, 0.64);"></label>
                <p>
                  <img src="../../assets/images/btfenlei.png">
                  <span>打开栏目</span>
                </p>
              </div>
              <div @click="itemInfo(img)" class="press">
                <label style="border:1px solid rgba(255, 255, 255, 0.64);"></label>
                <p>
                  <img src="../../assets/images/btxiangqing.png">
                  <span>查看详情</span>
                </p>
              </div>
            </div>
          </transition>
        </a>
      </li>
    </ul>
    <div class="opcity" v-show="opcity" @click="cancel($event)"></div>
    <transition
      enter-active-class="animated fadeInLeft"
      leave-active-class="animated fadeOutLeft"
    >
      <div class="slideModal" v-show="modal1">
        <div class="modalClose" @click="showModalone">
          <img src="../../assets/images/guanbi.png" />
        </div>
        <div class="clideCont">
          <h3>{{ classModal.name }}</h3>
          <div class="introduce"></div>
          <div class="clideText scrol" v-html="classModal.txt"></div>
          <div class="more" @click="goMore">查看更多></div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import iHeader from "@/components/Header.vue";
import iNavigate from "@/components/navigate.vue";
export default {
  name: "classify",
  components: {
    iHeader,
    iNavigate
  },
  data() {
    return {
      /*show:false,*/
      imgDate: [],
      modal1: false,
      opcity: false,
      entryShow: -1,
      showIndex: -1,
      data: [],
      examine: false,
      classModal: {},
      channel: {},
    };
  },
  props:{
    channelData:Object,
  },
  mounted() {},
  created() {
    this.getlassify();
  },
  methods: {

    /*划入显示按钮*/
    showSpan(item,index) {
      this.showIndex = index;
      if (item.nowItem) {
        this.entryShow = index;
      } else {
        this.entryShow = -1;
      }
    },
    // 划出
    showLeave() {
      this.showIndex = -1;
      this.entryShow = -1;
    },
    /**打开栏目 */
    openChannel(item) {
      this.$router.push({
        name: 'list',
        query: { sortId: item.uuid },
      });
    },
    /*查看详情*/
    itemInfo(item) {
      this.modal1 = true;
      this.opcity = true;
      this.classModal = item.nowItem;
    },
    // 关闭弹框
    showModalone() {
      this.modal1 = false;
      this.opcity = false;
    },
    cancel(event){
      let hide = document.querySelector('.slideModal')
      if(hide){
        if(!hide.contains(event.target)){
          this.modal1 = false;
          this.opcity = false;
        }
      }
    },
    getlassify() {
      let res = this.channelData;
      this.channel = res.channel;
      this.imgDate = res.child;
    },
      //查看更多
    goMore(){
      this.$router.push({
        name:'item',
        query:{sortId:this.classModal.uuid}
      })
    },
  },
};
</script>
<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  overflow: auto;
}
.index {
  background-image: url("../../assets/images/bg.png");
  background-size: 100% 100%;
  min-width: 1920px;
  overflow-x: auto;
  background-attachment: fixed;
  height: 100vh;
}
.ulFlex {
  display: flex;
  padding-top: 7%;
  justify-content: center;
  box-sizing: border-box;
}
.ulFlex::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
.ulFlex li {
  width: 12.5%;
  height: 621px;
  list-style: none;
  cursor: default;
  transition: all 0.4s;
  overflow: hidden;
  background-image: url(../../assets/images/dair.png);
  background-size: 98% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.ulFlex li:hover {
  width: 621px; /*621*/
  transition: all 0.4s;
}
.ulFlex li:nth-child(even) {
  margin-top: -80px;
}
.ulFlex li:last-child {
  margin-right: 0;
}
.ulFlex li a,
.ulFlex li .pict img {
  display: block;
  height: 100%;
  max-width: 662px;
}
.ulFlex li .pict img{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.ulFlex li:hover .pict img{
  width: 100%;
}
.ulFlex li a img[src = ''],.ulFlex li a img:not([src]){
  opacity: 0;
}
.ulFlex li a {
  margin: auto;
  width: 98%;
  position: relative;
  overflow: hidden;
}
.ulFlex li .swiperName {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 50px;
  background: rgba(193,73,99,.85);
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
  transition: all 0.3s;
  left: 0;
}
.bgOpcity{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.55);
  display: none;
}
.ulFlex li:hover .bgOpcity{
  display: block;
}
/*划入按钮*/
.entry {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.entry .press {
  width: 115px;
  height: 115px;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  position: relative;
}
.entry .press label{
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  cursor: pointer;
}
.entry .press:hover label{
  transition: .3s;
  transform: skewY(0) rotateZ(0deg) rotateX(8deg) rotateY(-180deg);
}
.entry .press img{
  width: 24px;
  height: 25px;
  padding-top: 30px;
  display: block;
  margin: auto;
}
.entry .press span{
  display: inline-block;
  padding-top: 9px;
}
.entry .press:first-child {
  margin-bottom: 58px;
}
/*弹框*/
.slideModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 732px;
  background: rgba(26, 26, 26, 0.97);
  z-index: 992;
  padding: 0px 30px;
  box-sizing: border-box;
}
.modalClose {
  width: 42px;
  height: 42px;
  transition: all 0.3s;
  text-align: center;
  line-height: 42px;
  border: 2px solid rgba(255, 255, 255, 0.33);
  border-radius: 14px;
  position: absolute;
  right: 20px;
  top: 28px;
}
.modalClose img {
  vertical-align: middle;
  transition: all 0.3s;
}
.clideCont {
  text-align: left;
  height: 100%;
}
.clideCont h3 {
  color: #ffffff;
  font-size: 30px;
  padding-top: 66px;
}
.introduce {
  padding: 20px 0px 40px 0px;
}
.introduce p {
  color: #ffffff;
  font-size: 14px;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.33);
}
.introduce p span {
  display: inline-block;
  width: 33.3%;
}
.clideText {
  font-size: 16PX;
  color: #ffffff;
  line-height: 35PX;
  max-height: 690px;
  overflow: hidden;
  overflow-y: auto;
  letter-spacing: 2px;
  padding-right: 10px;
}
.clideText >>> img {
  max-width: 100%;
  position: relative;
  /*left: 50%;
  transform: translate(-50%, 0);*/
}
.clideText >>> .imgTitle{
  text-align: center;
  font-size: 14PX;
}
.opcity {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.42);
  z-index: 991;
}
.more {
  font-size: 16PX;
  color: #ffffff;
  text-align: right;
  padding-top: 50px;
  cursor: pointer;
}
.boxhead{
  height: 90px;
}
.labelPage {
  font-size: 14px;
  font-weight: 500;
  color: #b3b3b3;
  text-align: left;
  padding-left: 25px;
  padding-top: 30px;
}
</style>
