<template>
  <div class="bg">
    <i-header></i-header>
    <img src="../../assets/images/bg.png" class="bgImg" />
    <div class="labelPage">
      <i-navigate :navigate="channel"></i-navigate>
    </div>
    <swiper :options="option" ref="swipers" v-if="swiperData.length">
        <swiper-slide v-for="(swiper, index) in swiperData" :key="index">
          <div
              class="salideDiiv"
              @mouseenter="swiperSpan(swiper, index)"
              @mouseleave="swiperLeave"
              @click="!swiper.nowItem && openChannel(swiper)"
          >
            <img :src="swiper.img" />
            <div class="bgOpcity"></div>
            <transition
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
            >
            <span  class="swiperName">{{swiper.name}}</span>
            </transition>
            <transition
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
            >
              <div class="entry" v-show="index === swiperIndex">
                <div @click="openChannel(swiper)" class="press">
                  <label style="border: 1px solid rgba(255, 255, 255, 0.64);"></label>
                  <p>
                    <img src="../../assets/images/btfenlei.png" />
                    <span>打开栏目</span>
                  </p>
                </div>
                <div @click="itemInfo(swiper)" class="press">
                  <label style="border: 1px solid rgba(255, 255, 255, 0.64);"></label>
                  <p>
                    <img src="../../assets/images/btxiangqing.png" />
                    <span>查看详情</span>
                  </p>
                </div>
              </div>
            </transition>
          </div>
        </swiper-slide>
      </swiper>
    <div class="swiper-pagination"></div>
    <div class="opcity" v-show="opcity" @click="onCancel($event)"></div>
    <transition
      enter-active-class="animated fadeInLeft"
      leave-active-class="animated fadeOutLeft"
    >
      <div class="slideModal" v-show="modal1">
        <div class="modalClose" @click="showModalone">
          <img src="../../assets/images/guanbi.png" />
        </div>
        <div class="clideCont">
          <h3>{{ modalData.name }}</h3>
          <div class="introduce"></div>
          <div class="clideText scrol" v-html="modalData.txt"></div>
          <div class="more" @click="goMore">查看更多></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import iHeader from "@/components/Header.vue";
import iNavigate from "@/components/navigate.vue";
export default {
  name: "dair",
  data() {
    return {
      option: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        /* simulateTouch : false,
        mousewheel: true,*/
        /*loop: true,*/
        pagination: {
          el: ".swiper-pagination",
          //clickable :true,
        },
        autoplay: true,
        speed: 1000,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
      swiperData: [],
      span: false,
      swiperIndex: -1,
      showIndex: -1,
      opcity: false,
      modal1: false,
      modalData: {},
      channel: {},
    };
  },
  props:{
    channelData:Object,
  },
  computed: {
    swiper() {
      return this.$refs.swipers.swiper;
    },
  },
  components: {
    iHeader,
    iNavigate,
  },
  created() {
    this.getSwiperList();
  },
  mounted() {

  },
  methods: {
    setSwiperInit() {
      setTimeout(() => {
        this.swiper.slideTo(Math.ceil(this.swiperData.length/2)-1, 50, false);
      }, 0);
      this.swiper.autoplay.start();
    },
    /*划入显示按钮*/
    swiperSpan(swiper, index) {
      this.showIndex = index;
      if (swiper.nowItem) {
        this.swiperIndex = index;
      } else {
        this.swiperIndex = -1;
      }
      this.swiper.autoplay.stop();
    },
    swiperLeave() {
      this.swiperIndex = -1;
      this.showIndex = -1;

      if (!this.modal1 && !this.swiper.destroyed) {
        this.swiper.autoplay.start();
      }
    },
    getSwiperList() {
      let res = this.channelData;
      this.channel = res.channel;
      for(let i=0;i<res.child.length;i++){
        if(res.child[i].img){
          res.child[i].img=res.child[i].img+"?h=500"
        }
      }
      this.swiperData = res.child;
      this.setSwiperInit()
    },
    /**打开栏目 */
    openChannel(item) {
      this.$router.push({
        name: 'list',
        query: { sortId: item.uuid },
      });
    },
    /*查看详情*/
    itemInfo(item) {
      this.modal1 = true;
      this.opcity = true;
      this.modalData = item.nowItem;
      this.swiper.autoplay.stop();
    },
    // 关闭弹框
    showModalone() {
      this.modal1 = false;
      this.opcity = false;
      this.swiper.autoplay.start();
      // this.modalData = {}
    },
    onCancel(event) {
      let hide = document.querySelector(".slideModal");
      if (hide) {
        if (!hide.contains(event.target)) {
          this.modal1 = false;
          this.opcity = false;
        }
      }
    },
    //查看更多
    goMore() {
      this.$router.push({
        name: "item",
        query: { sortId: this.modalData.uuid },
      });
    },
  },

};
</script>

<style scoped>
.bg {
  height: 100vh;
}
.swiper-container {
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -300px;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 402px;
  height: 600px;
  border-radius: 10px;
  position: relative;
  border-right: 10px;
  overflow: hidden;
  position: relative;
}
.bg .bgImg {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.swiper-slide {
  pointer-events: none;
}
.swiper-slide-active {
  pointer-events: auto;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
}
.swiper-slide img[src=""],
.swiper-slide img:not([src]) {
  opacity: 0;
}
.bgOpcity {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.45);
  display: none;
}
.swiper-slide:hover .bgOpcity {
  display: block;
}
.swiper-pagination{
  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
}
.bg >>> .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  width: 18px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  margin: 0px 4px;
}
.bg >>> .swiper-pagination .my-bullet-active {
  display: inline-block;
  background: #ffffff;
  opacity: 1;
}
/*划入按钮*/
.entry {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.entry .press {
  width: 115px;
  height: 115px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  position: relative;
}
.entry .press label {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  cursor: pointer;
}
.entry .press:hover label {
  transition: 0.3s;
  transform: skewY(0) rotateZ(0deg) rotateX(8deg) rotateY(-180deg);
}
.entry .press img {
  width: 24px;
  height: 25px;
  padding-top: 30px;
  display: block;
  margin: auto;
}
.entry .press span {
  display: inline-block;
  padding-top: 9px;
}
.entry .press:first-child {
  margin-bottom: 58px;
}
.swiper-slide .swiperName {
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 550px;
  height: 50px;
  background: rgba(193,73,99,.85);
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
  transition: all 0.3s;
  left: 0;
}
.salideDiiv {
  height: 100%;
  background-image: url(../../assets/images/dair.png);
  background-size: 100% 100%;
}
/*弹框*/
.opcity {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.42);
  z-index: 991;
}
.slideModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 732px;
  background: rgba(26, 26, 26, 0.97);
  z-index: 992;
  padding: 0px 30px;
  box-sizing: border-box;
}
.modalClose {
  width: 42px;
  height: 42px;
  transition: all 0.3s;
  text-align: center;
  line-height: 42px;
  border: 2px solid rgba(255, 255, 255, 0.33);
  border-radius: 14px;
  position: absolute;
  right: 20px;
  top: 28px;
}
.modalClose img {
  vertical-align: middle;
  transition: all 0.3s;
}
.clideCont {
  text-align: left;
  height: 100%;
}
.clideCont h3 {
  color: #ffffff;
  font-size: 30px;
  padding-top: 66px;
}
.introduce {
  padding: 20px 0px 40px 0px;
}
.introduce p {
  color: #ffffff;
  font-size: 14px;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.33);
}
.introduce p span {
  display: inline-block;
  width: 33.3%;
}
.clideText {
  font-size: 16PX;
  color: #ffffff;
  line-height: 35PX;
  max-height: 680px;
  overflow: hidden;
  overflow-y: auto;
  letter-spacing: 2px;
  padding-right: 10px;
}
.clideText >>> img {
  max-width: 100%;
  position: relative;
/*  left: 50%;
  transform: translate(-50%, 0);*/
}
.clideText >>> .imgTitle{
  text-align: center;
  font-size: 14PX;
}
.more {
  font-size: 16PX;
  color: #ffffff;
  text-align: right;
  padding-top: 50px;
  cursor: pointer;
}
.boxhead{
  height: 90px;
}
.labelPage {
  font-size: 14px;
  font-weight: 500;
  color: #b3b3b3;
  text-align: left;
  padding-left: 25px;
  padding-top: 30px;
  position: relative;
}
</style>
