<template>
  <div class="sector">
    <i-header></i-header>
    <div class="labelPage">
      <i-navigate :navigate="channel"></i-navigate>
    </div>
    <div class="torSwiper">
      <swiper :options="option" ref="swiperRef" @slideChange="(e) => onSlideChange(e)">
        <swiper-slide v-for="(item, index) in sectData" :key="index">
          <div class="sectorDiv" @mouseenter="mouseenterHandler(item, index)" @mouseleave="mouseleaveHandler">
            <img :src="item.img"/>
            <transition
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
            >
              <div class="entry itemButton">
                <div @click="openChannel(item)" class="press" >
                  <label style="border: 1px solid rgba(255, 255, 255, 0.64);"></label>
                  <p>
                    <img src="../../assets/images/btfenlei.png" />
                    <span>打开栏目</span>
                  </p>
                </div>
                <div @click="itemInfo(item)" class="press" v-show="item.nowItem">
                  <label style="border: 1px solid rgba(255, 255, 255, 0.64);"></label>
                  <p>
                    <img src="../../assets/images/btxiangqing.png" />
                    <span>查看详情</span>
                  </p>
                </div>
              </div>
            </transition>
            <div class="bgOpcity"></div>
          </div>
          <span class="slideName">{{ item.name }}</span>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <!--弹框-->
    <div class="opcity" v-show="opcity" @click="onCancel($event)"></div>
    <transition
        enter-active-class="animated fadeInLeft"
        leave-active-class="animated fadeOutLeft"
    >
      <div class="slideModal" v-show="modal1">
        <div class="modalClose" @click="showModalone">
          <img src="../../assets/images/guanbi.png" />
        </div>
        <div class="clideCont">
          <h3>{{modalData.name}}</h3>
          <div class="introduce"></div>
          <div class="clideText scrol" v-html="modalData.txt"></div>
          <div class="more" @click="goMore">查看更多></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import iHeader from "@/components/Header.vue";
import iNavigate from "@/components/navigate.vue";
import $ from "jquery";
export default {
  name: "sector",
  components: {
    iHeader,
    iNavigate,
  },
  created() {
    this.getSector();
  },
  data() {
    return {
      option: {
        effect : 'coverflow',
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: true,
        },
        slidesPerView: "auto",
        spaceBetween: 100,
        centeredSlides: true,
        loop: false,
        simulateTouch : false,
        speed:500,
        autoplay: {
          pauseOnMouseEnter: true,
          reverseDirection: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      channel: {},
      sectData: [],
      modal1: false,
      opcity:false,
      modalData:{}
    };
  },
  props:{
    channelData:Object,
  },
  methods: {
    mouseenterHandler(item,index){
      this.mySwiper.autoplay.stop();
    },
    mouseleaveHandler(){
      this.mySwiper.autoplay.start();
    },
    getSector() {
      let res = this.channelData;
      this.channel = res.channel;
      for(let i=0;i<res.child.length;i++){
        if(res.child[i].img){
          res.child[i].img=res.child[i].img+"?h=500"
        }
      }
      this.sectData = res.child;
      this.setSwiperInit();
    },
    /*展示中间图片*/
    setSwiperInit() {
      setTimeout(() => {
        //定时
        this.$refs.swiperRef.swiper.slideTo(
            parseInt(this.sectData.length / 2), 50, false
        );
        this.onSlideChange();
      }, 0);

    },
    onSlideChange() {
      this.$nextTick(() => {
        $(".swiper-slide").removeClass("prevClass").removeClass("prev2Class");
        $(".swiper-slide-prev").prev().addClass("prevClass").prev().addClass("prev2Class");
        this.mySwiper.autoplay.start();
      });
      const realIndex = this.mySwiper.realIndex;
      if (realIndex == 0) {
        this.mySwiper.params.autoplay.reverseDirection = false;
      } else if (realIndex == this.sectData.length - 1) {
        this.mySwiper.params.autoplay.reverseDirection = true;
      }
    },
    /**打开栏目 */
    openChannel(item){
      this.$router.push({
        name: 'list',
        query: { sortId: item.uuid },
      });
    },
    /*查看详情*/
    itemInfo(item) {
      this.modal1 = true;
      this.opcity = true;
      this.modalData=item.nowItem;
      this.$nextTick(() => {
        $('.swiper-wrapper').css('margin-left','-13px')
      })
    },
    // 关闭弹框
    showModalone() {
      this.modal1 = false;
      this.opcity = false
    },
    /*点击其他地方关闭*/
    onCancel(event) {
      let hide = document.querySelector(".slideModal");
      if (hide) {
        if (!hide.contains(event.target)) {
          this.modal1 = false;
          this.opcity = false;
        }
      }
    },
    goMore(){
      this.$router.push({
        name: "item",
        query: { sortId: this.modalData.uuid },
      });
    }
  },
  computed: {
    mySwiper() {
      return this.$refs.swiperRef.swiper;
    },
  },
};
</script>

<style scoped>
.boxhead{
  height: 90px;
}
/*目录条*/
.labelPage {
  font-size: 14px;
  font-weight: 500;
  color: #b3b3b3;
  text-align: left;
  padding-left: 25px;
  padding-top: 30px;
  position: relative;
}
.sector {
  height: 100%;
  background-image: url("../../assets/images/bg.png");
  background-size: 100% 100%;
}
.torSwiper {
  position: relative;
}
.swiper-container {
  height: 780px;
  padding-top: 70px;
}
.swiper-slide {
  width: 278px;
  height: 476px;
  transition: 0.8s;
  -ms-transition:0.8s;
}
.sectorDiv>img {
  width: 278px;
  height: 476px;
}
.swiper-slide .slideName {
  font-size: 42px;
  color: #ffffff;
  opacity: 0;
  position: absolute;
  bottom: -170px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.swiper-slide-active .slideName {
  opacity: 1;
}
.prevClass {
  transform: rotate(-16deg) !important;
  margin-top: 93px;
}
.prev2Class {
  transform: rotate(-23deg) !important;
  margin-top: 220px;
}
.swiper-slide-prev {
  transform: rotate(-8deg) !important;
  margin-top: 23px;
}
.swiper-slide-next {
  transform: rotate(8deg) !important;
  margin-top: 23px;
}
.swiper-slide-next + .swiper-slide {
  transform: rotate(16deg) !important;
  margin-top: 93px;
}
.swiper-slide-next + .swiper-slide ~ .swiper-slide {
  transform: rotate(23deg) !important;
  margin-top: 220px;
}
.swiper-pagination {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 50px;
}
.torSwiper >>> .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  width: 18px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  margin: 0px 4px;
}
.torSwiper >>> .swiper-pagination .my-bullet-active {
  display: inline-block;
  background: #ffffff;
  opacity: 1;
}
.swiper-button-prev:after, .swiper-button-next:after{
  display: none;
}
.swiper-button-prev{
  background-image: url("../../assets/images/sectorleft.png");
  background-size: 80px 61px;
  background-repeat: no-repeat;
}
.swiper-button-next{
  background-image: url("../../assets/images/sectorright.png");
  background-size: 80px 61px;
  background-repeat: no-repeat;
}
.swiper-button-prev, .swiper-button-next{
  top: 40%;
  width: 80px;
  height: 61px;
}
.bgOpcity{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.55);
  top: 0;
  left: 0;
  display: none;
  border-radius: 20px;
}
.itemButton{
  display: none;
}
.swiper-slide:hover .itemButton{
  display: block;
}
.swiper-slide:hover .bgOpcity{
  display: block;
}
.sectorDiv{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-image: url("../../assets/images/dair.png");
  background-size: 100% 100%;
}
.sectorDiv img[src=""],
.sectorDiv img:not([src]) {
  opacity: 0;
}
.swiper-slide >>> .swiper-slide-shadow-left,.swiper-slide >>> .swiper-slide-shadow-right{
  display: none;
}
/*划入按钮*/
.entry {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.entry .press {
  width: 115px;
  height: 115px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  position: relative;
}
.entry .press label {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  cursor: pointer;
}
.entry .press:hover label {
  transition: 0.3s;
  transform: skewY(0) rotateZ(0deg) rotateX(8deg) rotateY(-180deg);
}
.entry .press img {
  width: 24px;
  height: 25px;
  padding-top: 30px;
  display: block;
  margin: auto;
}
.entry .press span {
  display: inline-block;
  padding-top: 9px;
}
.entry .press:first-child {
  margin-bottom: 58px;
}
/*弹框*/
.opcity {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.42);
  z-index: 991;
}
.slideModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 732px;
  background: rgba(26, 26, 26, 0.97);
  z-index: 992;
  padding: 0px 30px;
  box-sizing: border-box;
}
.modalClose {
  width: 42px;
  height: 42px;
  transition: all 0.3s;
  text-align: center;
  line-height: 42px;
  border: 2px solid rgba(255, 255, 255, 0.33);
  border-radius: 14px;
  position: absolute;
  right: 20px;
  top: 28px;
}
.modalClose img {
  vertical-align: middle;
  transition: all 0.3s;
}
.clideCont {
  text-align: left;
  height: 100%;
}
.clideCont h3 {
  color: #ffffff;
  font-size: 30px;
  padding-top: 66px;
}
.introduce {
  padding: 20px 0px 40px 0px;
}
.introduce p {
  color: #ffffff;
  font-size: 14px;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.33);
}
.introduce p span {
  display: inline-block;
  width: 33.3%;
}
.clideText {
  font-size: 16PX;
  color: #ffffff;
  line-height: 35PX;
  max-height: 680px;
  overflow: hidden;
  overflow-y: auto;
  letter-spacing: 2px;
  padding-right: 10px;
}
.clideText >>> p {
  text-indent: 32px;
}
.clideText >>> img {
  max-width: 100%;
  position: relative;
/*  left: 50%;
  transform: translate(-50%, 0);*/
}
.clideText >>> .imgTitle{
  text-align: center;
  font-size: 14PX;
}
.more {
  font-size: 16PX;
  color: #ffffff;
  text-align: right;
  padding-top: 50px;
  cursor: pointer;
}
</style>
